import anime from "animejs";

const claimContent = document.querySelector('h1.home.claim-content.second');
console.log(claimContent)
const contentLengthSecond = claimContent.innerHTML.length
const claimAnimation = anime.timeline({
    targets: claimContent,
    duration: 5000,
    autoplay: true,
    width: [0, '100%']
});

const claimAnimationdotSecond = anime({
    targets: 'main.home .content-holder .secondLine .dot',
    opacity: [0, 1],
    loop: 10,
    direction: 'alternate',
    easing: 'easeInOutSine',
    duration: 250,
    autoplay: true
})

claimAnimation.add({
    targets: 'main h1.home.claim-content.second',
    width: ['0px', '100%'],
    easing: 'steps(' + contentLengthSecond + ')',
    duration: 2500,
    complete: function(anim) {

        //Cursor nach Animation ausblenden
        if (anim.completed) document.querySelector('main .secondLine .dot').style.display = 'none';
    }
})